import {handleKeyPressEnterOrSpace} from '@wix/wix-events-commons-statics'
import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {EventLink} from '../../styled-components/event-link'
import {DetailsButtonProps} from './index'

export const DetailsButton = ({mobile, event, navigateToDetailsPage, t}: DetailsButtonProps) => {
  const handleClick = () => navigateToDetailsPage(event)
  return (
    <EventLink
      primary={mobile}
      mobile={mobile}
      as="a"
      onKeyPress={handleKeyPressEnterOrSpace(handleClick)}
      onClick={handleClick}
      data-hook={DH.BUTTON_GO_TO_DETAILS}
    >
      {t('events.buttons.go-to-details')}
    </EventLink>
  )
}
