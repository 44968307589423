import {getEventId, isPastOrCanceled} from '@wix/wix-events-commons-statics'
import React from 'react'
import {hasOrder, isExpanded, isTicketedWithNoOrder} from '../../selectors/event'
import {CancelRsvpButton} from '../actions/cancel-rsvp-button'
import {DetailsButton} from '../actions/details-button'
import {DownloadTicketsButton} from '../actions/download-tickets-button'
import {GoToOrderButton} from '../actions/go-to-order-button'
import {ShareButton} from '../actions/share-button'
import {EventDetails} from '../event-details'
import {EventSummary} from '../event-summary'
import {ExpandableRow} from '../expandable-row'
import {NoEvents} from '../no-events'
import {DetailedEvent} from '../../types/state'
import {MobileDownloadTicketsButton} from '../actions/mobile-download-tickets-button'
import s from './events-list.scss'
import {EventsListProps, ActionProps} from '.'

export const EventsList = ({
  events,
  expandedEvents,
  toggleEventDetails,
  isOwner,
  loading,
  hasNoEvents,
  mobile,
  closeAllEvents,
  t,
}: EventsListProps) => {
  if (hasNoEvents && !loading) {
    return <NoEvents />
  }

  const handleToggle = (event: DetailedEvent) => {
    if (getEventId(event.event) === expandedEvents[0]) {
      closeAllEvents()
    } else {
      closeAllEvents()
      toggleEventDetails(event)
    }
  }

  return (
    <div className={mobile ? s.mobile : null}>
      {events.map((event) => (
        <ExpandableRow
          key={getEventId(event.event)}
          isExpanded={isExpanded(expandedEvents, event)}
          toggle={() => handleToggle(event)}
          arrowClass={s.arrow}
          mobile={mobile}
          t={t}
          details={
            <EventDetails
              event={event}
              primaryButton={<DetailsButton event={event} />}
              secondaryButton={<Action mobile={mobile} event={event} isOwner={isOwner} />}
              shareButton={<ShareButton event={event} />}
            />
          }
        >
          <EventSummary event={event} />
        </ExpandableRow>
      ))}
    </div>
  )
}

const Action = ({isOwner, mobile, event}: ActionProps) => {
  if (!isOwner) {
    return null
  }

  if (isTicketedWithNoOrder(event)) {
    return mobile ? <MobileDownloadTicketsButton event={event} /> : <DownloadTicketsButton event={event} />
  }

  if (hasOrder(event)) {
    if (mobile) {
      return <GoToOrderButton event={event} />
    } else {
      return <DownloadTicketsButton event={event} />
    }
  }

  if (isPastOrCanceled(event.event)) {
    return null
  }

  return <CancelRsvpButton event={event} />
}
