import classNames from 'classnames'
import React from 'react'
import {Text, TYPOGRAPHY} from 'wix-ui-tpa/Text'
import {classes} from './event-text.st.css'

export const EventText = (props) => (
  <Text
    typography={TYPOGRAPHY.runningText}
    {...props}
    className={classNames(
      classes.root,
      {[classes.faded]: props.faded, [classes.large]: props.large},
      props.extraClass ? props.extraClass : null,
    )}
  />
)
