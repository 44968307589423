import classNames from 'classnames'
import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Divider} from '../styled-components/divider'
import {ExpandIcon} from './expand-icon/expand-icon'
import s from './expandable-row.scss'
import {ExpandableRowProps} from '.'

export const ExpandableRow = ({
  children,
  details,
  isExpanded,
  toggle,
  arrowClass,
  mobile,
  href,
  navigable,
  divider,
  t,
}: ExpandableRowProps) => {
  const showDivider = divider === undefined || divider

  return (
    <div className={classNames(s.root, {[s.mobile]: mobile})} data-hook={DH.EXPANDABLE_ROW}>
      <div className={s.content}>
        <div className={s.container}>
          <div className={s.summary}>{children}</div>
          <div className={s.expandIcon}>
            <ExpandIcon
              mobile={mobile}
              extraClass={arrowClass}
              expanded={isExpanded}
              onClick={toggle}
              href={href}
              right={navigable}
              dataHook={DH.EXPANDABLE_ROW_ICON}
              t={t}
            />
          </div>
        </div>
        {isExpanded ? details : null}
      </div>
      {showDivider ? <Divider /> : null}
    </div>
  )
}
