import {withTranslation, WithTranslation} from 'react-i18next'
import {OrderDetails as OrderDetailsPresentation} from './order-details'

export interface OrderDetailsProps extends OrderDetailsOwnProps, OrderDetailsRuntimeProps, WithTranslation {}

interface OrderDetailsOwnProps {
  order: wix.events.ticketing.Order
}

interface OrderDetailsRuntimeProps {}

export const OrderDetails = withTranslation()(OrderDetailsPresentation)
