import classNames from 'classnames'
import React from 'react'
import {Resizable} from '../../../commons/components/hoc/resizable'
import {Direction} from '../../../commons/constants/html'
import {ROOT_COMPONENT_ID} from '../../config/constants'
import {Router} from '../router'
import s from './app-container.scss'
import {AppContainerProps} from './index'

export const AppContainer = ({resize, mobile, route, isRTL}: AppContainerProps) => (
  <div dir={isRTL ? Direction.RTL : Direction.LTR}>
    <div id={ROOT_COMPONENT_ID} className={classNames(s.root, {[s.mobile]: mobile})}>
      <Resizable resizeFunc={resize}>
        <Router route={route} mobile={mobile} />
      </Resizable>
    </div>
  </div>
)
