import React from 'react'
import {EventsList} from '../events-list'
import {EventsTabs} from '../events-tabs'
import {Header} from '../header'
import {EventsProps} from './index'

export const Events = ({mobile}: EventsProps) => (
  <>
    {mobile ? null : <Header />}
    <EventsTabs />
    <EventsList />
  </>
)
