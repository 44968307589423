import {withTranslation} from 'react-i18next'
import {isMobile} from '../../../commons/selectors/environment'
import {getEventList, hasNoEvents} from '../../selectors/events'
import {isOwnProfile} from '../../selectors/user'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventsList as EventsListPresentation} from './events-list'
import {EventsListRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions}: MemberPageContext): EventsListRuntimeProps => ({
  hasNoEvents: hasNoEvents(state),
  expandedEvents: state.expandedEvents,
  toggleEventDetails: actions.toggleEventDetails,
  loading: state.loading,
  isOwner: isOwnProfile(state),
  mobile: isMobile(state),
  events: getEventList(state),
  closeAllEvents: actions.closeAllEvents,
})

export const EventsList = connect<{}, EventsListRuntimeProps>(mapRuntime)(withTranslation()(EventsListPresentation))
export * from './interfaces'
