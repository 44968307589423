import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {EventLink} from '../../styled-components/event-link'
import {ShareButtonProps} from './index'

export const ShareButton = ({mobile, shareEvent, event, t}: ShareButtonProps) => (
  <EventLink mobile={mobile} onClick={() => shareEvent(event)} data-hook={DH.BUTTON_SHARE}>
    {t('events.buttons.share-event')}
  </EventLink>
)
