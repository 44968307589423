import React from 'react'
import ChevronDown from 'wix-ui-icons-common/ChevronDown'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {EventText} from '../styled-components/event-text'
import s from './back.scss'
import {BackProps} from './index'

export const Back = ({onClick, href, t}: BackProps) => (
  <a onClick={onClick} href={href} className={s.root} data-hook={DH.BACK_BUTTON}>
    <ChevronDown size="14px" viewBox="8 7 9 10" className={s.icon} />
    <EventText extraClass={s.text}>{t('button.back')}</EventText>
  </a>
)
